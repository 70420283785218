import { Component } from '@angular/core';
import { Platform } from '@angular/cdk/platform';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public static isDesktop: Boolean;
  public MOBILE_WIDTH = 768;
  constructor(private pl: Platform ) {}

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.initializeApp();
   }
  
  initializeApp() {
    AppComponent.isDesktop = (this.pl.isBrowser && window.innerWidth > this.MOBILE_WIDTH) ? true : false;
  }
}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //  baseUrl:  "https://localhost:44326",
  //  soApiUrl: "https://localhost:44326/api/",
  // baseUrl:  "https://localhost:5001",
  // soApiUrl: "https://localhost:5001/api/",
   baseUrl: "https://nmao-api-stage.azurewebsites.net",
   soApiUrl: "https://nmao-api-stage.azurewebsites.net/api/",
    minorLink: "https://apptest.loanspq.com/apply.aspx?enc=li4vaDVj_-KYKOppTSH6EM9_e-Bochy9R-luDX83Ag_zUoI9pXSevRkeUphwJ5KAm7luw4F8ETsTOEdJxLxcosE7kKmw4LMJvs4Zxyil_tw",

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
